@if (!cookieBannerHidden()) {
    <div class="cookies-wrap box-shadow radius-8" [class.dismissed]="cookiesStatus()">
    
        <!-- @if (!preferences) { -->
            <div class="title-wrap">
                <span class="title">Deze site maakt gebruik van cookies</span>
            </div>
    
            <p>
                We gebruiken analytische cookies om informatie te verzamelen over het gebruik van onze website om de inhoud te verbeteren. Door hieronder op “Accepteren” te klikken, gaat u akkoord met de installatie en het gebruik van al onze cookies. 
                Raadpleeg voor meer informatie ons <a [href]="['privacybeleid']" target="_blank">cookiebeleid</a>
            </p>
            
            <div class="buttons">
                <button (click)="accept()" class="app-button primary-button">Accepteer</button>
                <button (click)="decline()" class="app-button primary-button--inverted">Weiger</button>
            </div>
        <!-- } -->
    
    </div>
}
import { Component, inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ConfigService } from 'src/app/shared/services/config.service';

@Component({
    selector: 'app-cookie-banner',
    standalone: true,
    imports: [],
    templateUrl: './cookie-banner.component.html',
    styleUrl: './cookie-banner.component.scss'
})
export class CookieBannerComponent implements OnInit {
    cookiesStatus = inject(ConfigService).cookiesStatus;
    cookieBannerHidden = inject(ConfigService).cookieBannerHidden;

    // preferences: boolean = false;

    constructor(public Router: Router) {
        setTimeout(() => {
            this.Router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    // gtag('config', 'TODO', {
                    //     page_path: event.urlAfterRedirects
                    // });
                }
            });
        }, 1);
    }

    ngOnInit(): void {
        this.cookiesStatus.set(localStorage.getItem('cookies') as 'accepted' | 'declined');

        if (!this.cookiesStatus()) {
            this.cookieBannerHidden.set(false);
        } else {
            this.cookieBannerHidden.set(true);
        }

        if (this.cookiesStatus() === 'accepted') {
            // gtag('js', new Date());
            // gtag('config', 'TODO');
        }
    }

    accept() {
        localStorage.setItem('cookies', 'accepted');
        this.cookiesStatus.set('accepted');
        // gtag('consent', 'default', {
        //     analytics_storage: 'granted'
        // });
        // gtag('js', new Date());
        // gtag('config', 'TODO');
        this.cookieBannerHidden.set(true);
    }

    decline() {
        localStorage.setItem('cookies', 'declined');
        this.cookiesStatus.set('declined');
        this.cookieBannerHidden.set(true);
    }
}

<footer>
    <div [ngStyle]="{'backgroundColor': bannerColor}">
        <div class="banner content content--center">
            <img class="logo logo--collapsed" src="/assets/img/misc/logo-collapsed-meldpunt.svg" alt="Meldpunt logo collapsed">
            <div class="banner-text">
                Een initiatief van het Instituut Verantwoord Medicijngebruik
            </div>
        </div>
    </div>
    <div class="footer-content background-color--primary pt-48 pb-104">
        
        <div class="content content--center d-flex flex-column gap-24">
            <div>
                <img class="logo" src="/assets/img/misc/logo-meldpunt--white.svg" alt="Meldpunt logo">
            </div>

            <div class="cluster cluster--footer-content">
                <div class="cluster-item gap-24 color-white">
                
                    <p>
                        © 2024 - Een initiatief van het Instituut Verantwoord Medicijngebruik
                    </p>

                    <a [routerLink]="'aanmelden-medicijnpanel'" class="app-button primary-button--inverted d-flex align-center gap-8 medicijnpaneel">
                        Aanmelden medicijnpanel
                        <i class="icon icon-login icon-size--24"></i>
                    </a>
                </div>
                <div class="cluster-item footer-links gap-24">
                    <div class="cluster-item gap-24">
                        @for (page of pages; track page.slug) {
                            @if ($index < splitHalf) {
                                <a [routerLink]="page.slug" [fragment]="page.fragment" class="footer-link">{{page.titel}}</a>
                                @for (subpage of page.subpaginas; track subpage.slug) {
                                    <a [routerLink]="[page.slug, subpage.slug]" class="footer-link subpage-link">{{subpage.titel}}</a>
                                }
                            }
                        }
                    </div>
                    <div class="cluster-item gap-24">
                        @for (page of pages; track page.slug) {
                            @if ($index >= splitHalf) {
                                <a [routerLink]="page.slug" [fragment]="page.fragment" class="footer-link">{{page.titel}}</a>
                                @for (subpage of page.subpaginas; track subpage.slug) {
                                    <a [routerLink]="[page.slug, subpage.slug]" class="footer-link subpage-link">{{subpage.titel}}</a>
                                }
                            }
                        }
                    </div>
                </div>
    
            </div>


            
        </div>
    </div>
</footer>
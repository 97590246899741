import { Component, Inject, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { RoutingService } from './shared/services/routing.service';
import { ConfigService } from './shared/services/config.service';
import { AppService } from './shared/services/app.service';
import { PublicNavigationComponent } from './modules/public/components/layout/public-navigation/public-navigation.component';
import { ServerConfig } from './shared/models/config.model';
import { toSignal } from '@angular/core/rxjs-interop';
import { ResponsiveService } from './shared/services/responsive.service';
import { FooterComponent } from './shared/components/footer/footer.component';
import { DefaultService } from './utils/api';
import { Page } from 'backend/src/models/page';
import { lastValueFrom } from 'rxjs';
import { PopupComponent } from './shared/components/popup/popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieBannerComponent } from './modules/public/components/modals/cookie-banner/cookie-banner.component';
import { NavigationService } from './shared/services/navigation.service';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [
        CommonModule,
        RouterOutlet,
        PublicNavigationComponent,
        PublicNavigationComponent,
        FooterComponent,
        PopupComponent,
        CookieBannerComponent
    ],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
    configService = inject(ConfigService);
    appService = inject(AppService);
    routingService = inject(RoutingService);
    navigationService = inject(NavigationService);
    responsiveService = inject(ResponsiveService);
    defaultService = inject(DefaultService);
    modalService = inject(NgbModal);

    ready: boolean = false;
    version: string;
    screenSize = toSignal(this.responsiveService.onResize$, {
        initialValue: this.responsiveService.getScreenSize(window.innerWidth)
    });
    // pages: Page[];

    constructor(@Inject('config') private config: ServerConfig) {}

    async ngOnInit() {
        this.appService.setLanguage();
        this.configService.serverConfig.set(this.config);
        this.version = this.appService.getVersion();
        await this.routingService.init();
        await this.navigationService.initNavigation();
        this.ready = true;

        this.getPopup();
    }

    getPopup() {
        this.defaultService.popupGetPopup().subscribe((next) => {
            // if (next?.data) {
            const exist = next?.data && Object.keys(next?.data) ? !!Object.keys(next?.data)?.length : false;
            if (exist) {
                const modalRef = this.modalService.open(PopupComponent, {
                    size: 'lg',
                    beforeDismiss: () => {
                        return true;
                    }
                });
                modalRef.componentInstance.popup = next?.data;
            }
        });
    }
}

<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="ie=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <link rel="icon" type="image/x-icon" href="/favicon.ico">
    <title>404 - Pagina niet gevonden</title>
    <style>
        a{
            text-decoration: none;
        }
        body {
            line-height: 1;
            height: 100vh;
            margin: 0;
            padding: 0;
            background: radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, #F7FBFE 100%);
        }
        body * {
            font-family: 'Roboto', sans-serif;
        }
        h1{
            font-size: 34px;
            margin-top: -20px;
            font-weight: 500;
            color: #151515;
            font-size: 40px;
            margin-bottom: 22px;
        }
        .error-page {
            /* background: #f7fbfe;
            background: white; */
            width: 100%;
            position: relative;
            z-index: 1;
            font-family: sans-serif;
            font-size: 16px;
            color: #555555;
            padding-top: 60px;
        }
        .wrap{
            max-width: 800px;
            display: block;
            margin: auto;
            text-align: center;
            padding-left: 20px;
            padding-right: 20px;
            box-sizing: border-box;
            position: relative;
        }
        .wrapTop{
            color: #C7CED2;
            display: block;
            margin: auto;
            padding-top: 105px;
        }
        .wrapTop span{
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            font-size: 330px;
            font-weight: bold;
            z-index: 8;
            color: #B7CFA5;
            width: 100%;
        }
        .wrapTop svg{
            width: 280px;
            height: auto;
            position: relative;
            z-index: 9;
            max-width: 100%;
            transform: translatex(-25px);
            margin-top: -45px;
        }
        .wrapBottom{
            font-weight: 300;
            font-size: 16px;
            line-height: normal;
            padding-bottom: 40px;
        }
        .wrapBottom p{
            margin-top: 18px;
            line-height: 36px;
            font-size: 20px;
            color: #424745;
        }
        .wrapBottom p a{
            text-decoration: none;
            color: #008ae3;
        }
        .wrapBottom>a{
            display: inline-block;
            background: #1A2846;
            border-radius: 2px;
            color: white !important;
            padding: 14px 32px;
            cursor: pointer;
            border-radius: 4px;
            transition: .2s ease;
            text-decoration: none !important;
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 1px;

        }
        .wrapBottom>a:hover{
            filter: brightness(1.1);
        }
        .wrapBottom>a:hover svg{
            transform: translateX(3px);
        }
        .wrapBottom>a svg{
            margin-left: 4px;
            transition: .2s ease;
        }
        @media only screen and (max-width: 750px) {
            .wrapTop span{
                font-size: 47vw;
                margin-top: calc(128px - 20%);
            }
            .wrapTop svg{
                width: 49% !important;
                max-width: 320px !important;
                margin-top: calc(-16% + 39px);
                margin-left: calc(49px - 9%);
            }
            .error-page{
                padding-top: 7%;
            }
            h1{
                font-size: 30px;
                margin-top: -5px;
            }
            .wrapBottom p{
                line-height: 20px;
                font-size: 16px;
            }
        }
    </style>
</head>
<body>
    <div class="error-page">
        <div class="wrap">
            <div class="wrapTop">
                <span>404</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="307" height="419" viewBox="0 0 307 419">
                    <defs>
                        <filter id="27777q5hoa" width="163.5%" height="141.7%" x="-31.7%" y="-21%" filterUnits="objectBoundingBox">
                            <feOffset dy="13" in="SourceAlpha" result="shadowOffsetOuter1"/>
                            <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="10.5"/>
                            <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.136309003 0"/>
                            <feMerge>
                                <feMergeNode in="shadowMatrixOuter1"/>
                                <feMergeNode in="SourceGraphic"/>
                            </feMerge>
                        </filter>
                    </defs>
                    <g fill="none" fill-rule="evenodd">
                        <g>
                            <g filter="url(#27777q5hoa)" transform="translate(-651 -196) translate(700.654 246.685)">
                                <ellipse cx="106.346" cy="308.815" fill="#F5F5F5" rx="95" ry="10.5"/>
                                <g>
                                    <g>
                                        <path fill="#151515" d="M16.95 94L25.945 102 7.955 102zM71.95 94L80.945 102 62.955 102z" transform="translate(72.394 206.315)"/>
                                        <path fill="#6F513E" d="M4.953 71H28.953V97H4.953zM59.953 71H83.953V97H59.953z" transform="translate(72.394 206.315)"/>
                                        <path fill="#523A2C" d="M6.299 0L91.339 0 91.339 71 0 71z" transform="translate(72.394 206.315)"/>
                                        <path fill="#59522B" d="M15.033 10.122L35.323 48.346 64.033 11.667z" transform="translate(72.394 206.315)"/>
                                        <path fill="#FFF" d="M16.953 10.122c6.866 15.526 13.03 23.29 18.488 23.29 5.46 0 14.656-7.249 27.592-21.745l-46.08-1.545z" transform="translate(72.394 206.315)"/>
                                        <path fill="#424745" d="M32.041 12.648l7.06.526-1.634 3.183 3.085 15.536c-1.964 1.012-3.668 1.519-5.11 1.519-1.613 0-3.286-.677-5.02-2.03l2.91-15.025-1.29-3.709z" transform="translate(72.394 206.315)"/>
                                    </g>
                                    <g transform="translate(29.17 88.88)">
                                        <path fill="#F5CCA5" d="M12.03 0l.578 99.02c16.236 21.06 41.839 31.589 76.807 31.589 34.969 0 61.074-11.917 78.316-35.751l2.306-88.854L12.03 0z"/>
                                        <circle cx="120.066" cy="44.234" r="17" fill="#151515"/>
                                        <circle cx="44.799" cy="41.407" r="11.024" fill="#151515"/>
                                        <path fill="#523A2C" d="M2 101.982L19.733 106.354 67.693 87.944 114.773 104.071 137.714 91.526 113.703 91.283 82.881 61.354 67.108 67.568 53.344 61.354 28.229 86.204z" transform="rotate(3 69.857 83.854)"/>
                                        <path fill="#523A2C" d="M11.793 0.278L12.03 26.722 46.107 2.057z"/>
                                        <path fill="#523A2C" d="M142.033 6.004L142.045 41.407 170.05 8.15z" transform="matrix(-1 0 0 1 312.082 0)"/>
                                    </g>
                                    <g>
                                        <path fill="#6F513E" d="M26 39.747h29.738V52.52c.222 2.46 1.58 3.69 4.072 3.69s4.214-1.23 5.165-3.69L72.673 0h2.687L76 61.244C74.501 75.748 66.168 83 51 83s-23.501-7.252-25-21.756V39.747z" transform="rotate(12 -739.319 190.706)"/>
                                        <path fill="#151515" d="M71.027 11.219L75.488 11.58 75.488.229 72.563.229zM26 39L55.75 39 55.75 41 26 41z" transform="rotate(12 -739.319 190.706)"/>
                                        <path fill="#F5CCA5" d="M14.352 54.518l32.882-1.333c3.906 12.23 5.859 21.35 5.859 27.362 0 6.012-3.465 15.184-10.395 27.514l-23.007-.858-5.34-52.685z" transform="rotate(12 -739.319 190.706) scale(-1 1) rotate(56 0 17.201)"/>
                                    </g>
                                    <g transform="translate(39.868)">
                                        <ellipse cx="88.978" cy="7.315" fill="#59522B" rx="6.5" ry="2" transform="rotate(5 88.978 7.315)"/>
                                        <path fill="#6D673B" d="M159.339 94.884c.77-53.9-34.454-87.069-78.361-87.069C37.07 7.815 1.33 44.973 1.33 88.88c0 .313 158.008 6.004 158.008 6.004z"/>
                                        <path fill="#7C764B" d="M129.339 94.884c.77-53.9-26.441-87.069-48.361-87.069-21.92 0-39.647 37.158-39.647 81.065 0 .313 88.008 6.004 88.008 6.004z"/>
                                        <path fill="#59522B" d="M1 91.073l158.302 1.044-.14.005c-44.916 10.103-76.307 15.155-94.173 15.155s-39.187-5.135-63.961-15.405l-.028-.8z" transform="rotate(2 80.15 99.175)"/>
                                        <path stroke="#59522B" stroke-width="2" d="M95.334 7.815c9.034-3.94 16.148-5.41 21.342-4.41 7.79 1.502 8.986 6.892 4.191 8.015-3.694.865-12.205-.337-25.533-3.605zM82.094 6.702C73.357 2.141 66.363.18 61.112.815c-7.877.954-9.445 6.248-4.74 7.702 3.625 1.12 12.2.516 25.722-1.815z"/>
                                    </g>
                                </g>
                                <g transform="rotate(-14 508.746 -399.418)">
                                    <path fill="#909090" d="M32.071-.004c17.673 0 32 14.327 32 32 0 17.001-13.259 30.906-30 31.938v73.062h-4V63.934c-16.741-1.032-30-14.937-30-31.938 0-17.673 14.327-32 32-32zM32 4C16.536 4 4 16.536 4 32s12.536 28 28 28 28-12.536 28-28S47.464 4 32 4z"/>
                                    <path fill="#6F513E" d="M29.1 76.996h6.035c.546 0 .991.438 1 .984l.92 58c.009.552-.432 1.007-.984 1.016H28.09c-.552 0-1-.448-1-1v-.018l1.012-58c.01-.545.454-.982 1-.982z"/>
                                    <circle cx="32" cy="32" r="28" fill="#8C8C8C" fill-opacity=".124"/>
                                    <path fill="#E9BC91" d="M16.071 81.329l32.883-1.333c3.905 12.23 5.858 21.35 5.858 27.362 0 6.012-3.465 15.183-10.394 27.514l-23.007-.858-5.34-52.685z"/>
                                    <path stroke="#FFF" stroke-linecap="round" d="M37.245 8.192c7.742 1.905 13.116 5.608 16.123 11.11"/>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
                
                             
            </div>
            <div class="wrapBottom">
                <h1>Pagina niet gevonden.</h1>
                <p style="
                    max-width: 1000px;
                    display: block;
                    margin-left: auto;
                    margin-right: auto;">
                    De pagina die je zoekt is mogelijk verwijderd of bestaat niet.
                </p>
                <a href="/">
                    Ga naar de homepagina
                </a>
            </div>
        </div>
    </div>
    <div class="fill"></div>
</body>
</html>
import { Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { BehaviorSubject, map, shareReplay, switchMap, tap } from 'rxjs';
import { ImageBannerComponent } from 'src/app/shared/components/banners/image-banner/image-banner.component';
import { DefaultService } from 'src/app/utils/api';
import { SafePipe, formatTsPipe } from '../../../../utils/pipes';
import { SpinnerComponent } from 'src/app/shared/components/misc/spinner/spinner.component';
import { DefaultSearchObject } from 'src/app/shared/models/search.model';
import { InlineSpinnerComponent } from '../../../../shared/components/misc/inline-spinner/inline-spinner.component';
import { RouterLink } from '@angular/router';
import { ButtonComponent } from '../../../../shared/components/misc/button/button.component';

@Component({
    selector: 'app-news',
    standalone: true,
    imports: [
        ImageBannerComponent,
        SafePipe,
        formatTsPipe,
        SpinnerComponent,
        InlineSpinnerComponent,
        RouterLink,
        ButtonComponent
    ],
    templateUrl: './news.component.html',
    styleUrl: './news.component.scss'
})
export class NewsComponent {
    defaultService = inject(DefaultService);
    bannerHeight: number = 150;
    ready: boolean = false;
    loading: boolean = true;
    skeletonCount = Array(31);
    totalRows: number;

    search$ = new BehaviorSubject<DefaultSearchObject>({
        startrow: 0,
        RPP: 31,
        orderby: { code: 'sequence', dir: 'asc' }
    });

    news$ = this.search$.pipe(
        tap(() => {
            this.loading = true;
        }),
        switchMap((searchParams) =>
            this.defaultService
                .newsitemGetNewsitems({
                    startrow: searchParams.startrow,
                    orderby: [`${searchParams.orderby.code} ${searchParams.orderby.dir}`],
                    rpp: searchParams.RPP
                })
                .pipe(
                    tap((res) => {
                        this.totalRows = res.rows;
                    }),
                    map((res) => res.data),
                    map((data) =>
                        data.map((x) => ({
                            ...x,
                            afbeeldingURL: x.afbeeldingURL ?? 'assets/img/misc/ivm-fallback-image.jpg'
                        }))
                    )
                )
        ),
        tap(() => {
            this.loading = false;
            this.ready = true;
        }),
        shareReplay(1)
    );

    news = toSignal(this.news$, {
        initialValue: []
    });

    loadmore(newValues: Partial<DefaultSearchObject>) {
        this.search$.next({ ...this.search$.value, ...newValues });
    }
}

import { Component, inject, ViewChild, OnInit, signal, HostListener } from '@angular/core';
import { ImageBannerComponent } from 'src/app/shared/components/banners/image-banner/image-banner.component';
import { DefaultService, Medicine, NotificationGetRecentNotificationsRequestParams } from 'src/app/utils/api';

import {
    BehaviorSubject,
    catchError,
    combineLatest,
    debounceTime,
    distinctUntilChanged,
    filter,
    lastValueFrom,
    map,
    merge,
    Observable,
    of,
    OperatorFunction,
    shareReplay,
    Subject,
    switchMap,
    tap
} from 'rxjs';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { FieldTextComponent } from '../../../../shared/components/fields/field-text/field-text.component';
import { FieldCheckboxesComponent } from '../../../../shared/components/fields/field-checkboxes/field-checkboxes.component';
import { RadioOption } from 'src/app/shared/components/fields/field-radios/field-radios.component';
import { Medicijnonderwerp } from 'src/app/shared/components/medicine-form/medicine-form.component';
import { FieldTypeaheadComponent } from '../../../../shared/components/fields/field-typeahead/field-typeahead.component';
import {
    FieldSelectComponent,
    SelectOption
} from '../../../../shared/components/fields/field-select/field-select.component';
import { ButtonComponent } from 'src/app/shared/components/misc/button/button.component';
import { InlineSpinnerComponent } from 'src/app/shared/components/misc/inline-spinner/inline-spinner.component';
import { toSignal } from '@angular/core/rxjs-interop';
import { JsonPipe, NgClass } from '@angular/common';
import { formatTsPipe, SafePipe } from 'src/app/utils/pipes';
import { ActivatedRoute, Params, Router, RouterLink, RouterOutlet } from '@angular/router';
import { animate, group, query, style, transition, trigger } from '@angular/animations';
interface SearchMedicineForm {
    rpp?: number;

    medicijn?: string;
    ervaring?: string;
    onderwerp?: string[];
    medicijnGroep?: string;
}
// interface SearchParams extends SearchMedicineFormSearchMedicineForm {
//     // rpp?: number;
//     startrow: number;
//     // orderby: { code: string; dir: string };

//     // medicijn?: string;
//     // ervaring?: string;
//     // onderwerp?: string[];
//     // medicijnGroep?: string;
// }
@Component({
    selector: 'app-search-ervaringen',
    standalone: true,
    imports: [
        ImageBannerComponent,
        FormsModule,
        NgbTypeahead,
        FieldTextComponent,
        FieldCheckboxesComponent,
        FieldTypeaheadComponent,
        FieldSelectComponent,
        ButtonComponent,
        InlineSpinnerComponent,
        JsonPipe,
        SafePipe,
        formatTsPipe,
        RouterOutlet,
        RouterLink,
        NgClass
    ],
    templateUrl: './search-ervaringen.component.html',
    styleUrl: './search-ervaringen.component.scss',
    animations: [
        trigger('slideUp', [
            transition('* <=> *', [
                // query(':enter .overlay', [style({})], { optional: true }),
                query(
                    ':enter .medicine-detail-wrapper--mobile',
                    //slide up
                    [
                        style({
                            bottom: '-70%',
                            opacity: '.4'
                        }),
                        animate(
                            '300ms ease',
                            style({
                                bottom: 0,
                                opacity: 1
                            })
                        )
                    ],
                    { optional: true }
                ),
                query(
                    ':enter .medicine-detail-wrapper--desktop',
                    // appear
                    [
                        style({
                            // transform: 'translate(-50%, 50%)',
                            top: '70%',
                            scale: '.8',
                            opacity: '.4'
                        }),
                        animate(
                            '300ms ease',
                            style({
                                top: '10%',
                                scale: 1,
                                opacity: 1
                            })
                        )
                    ],
                    { optional: true }
                ),
                group([
                    query(
                        ':leave .medicine-detail-wrapper--mobile',
                        // slide down
                        [
                            style({
                                bottom: 0,
                                scale: 1,
                                opacity: 1
                            }),
                            animate(
                                '300ms ease',
                                style({
                                    bottom: '-70%',
                                    opacity: '.4'
                                })
                            )
                        ],
                        { optional: true }
                    ),
                    query(
                        ':leave .medicine-detail-wrapper--desktop',
                        // disappear
                        [
                            style({
                                // top: '10%',
                                scale: '.8',
                                opacity: '.4'
                            }),
                            animate(
                                '300ms ease',
                                style({
                                    top: '70%',
                                    scale: '.8',
                                    opacity: 0
                                })
                            )
                        ],
                        { optional: true }
                    ),
                    query(
                        ':leave .overlay',
                        [
                            style({
                                scale: 1,
                                opacity: 1
                            }),
                            animate(
                                '120ms ease',
                                style({
                                    scale: '.6',
                                    opacity: '0'
                                })
                            )
                        ],
                        { optional: true }
                    )
                ])
            ])
        ])
    ]
})
export class SearchErvaringenComponent implements OnInit {
    defaultService = inject(DefaultService);
    router = inject(Router);
    route = inject(ActivatedRoute);
    bannerHeight: number = 150;
    ready: boolean = false;
    loading: boolean = false;
    FORM: SearchMedicineForm = {
        rpp: 30
    };
    totalRows: number;
    expandForm: boolean = false;
    skeletonCount = Array(30);
    startrow: number = 0;
    RPP: number = 30;
    SORT = { code: 'createTS', dir: 'desc' };

    medicijnGroupOptions = signal<SelectOption[]>([]);

    RPP$ = new BehaviorSubject<number>(30);

    searchParams$ = this.route.queryParams.pipe(
        map((params) => params?.search),
        distinctUntilChanged(),
        map((search) => {
            if (search) {
                try {
                    return JSON.parse(search) ?? undefined;
                } catch (e) {
                    return {};
                }
            } else {
                return {};
            }
        }),
        catchError((error) => {
            return of({}); // Handle observable error by returning undefined
        }),
        shareReplay(1)
    );

    recentNotifications$ = combineLatest([this.searchParams$, this.RPP$]).pipe(
        tap(() => {
            this.loading = true;
        }),
        switchMap(([params, rpp]) => {
            this.FORM.medicijn = params?.medicijn;
            // this.FORM.medicijn = result?.medicijn;
            this.FORM.ervaring = params?.ervaring;
            this.FORM.onderwerp = params?.onderwerp;
            this.FORM.medicijnGroep = params?.medicijnGroep;
            if (params?.medicijn || params?.onderwerp || params?.medicijnGroep) this.expandForm = true;

            return this.defaultService
                .notificationGetRecentNotifications({
                    startrow: this.startrow,
                    orderby: [`${this.SORT.code} ${this.SORT.dir}`],
                    rpp: rpp ?? this.RPP,
                    term: params?.medicijn,
                    experience: params?.ervaring,
                    overWerking: params?.onderwerp?.includes('werking'),
                    overBijwerkingen: params?.onderwerp?.includes('bijwerkingen'),
                    overPraktisch: params?.onderwerp?.includes('praktisch'),
                    medicijngroep: params?.medicijnGroep
                })
                .pipe(
                    tap((res) => {
                        this.totalRows = res.rows;
                    }),
                    map((res) => res.data)
                );
        }),
        tap(() => {
            this.loading = false;
            this.ready = true;
        }),
        shareReplay(1)
    );

    recentNotifications = toSignal(this.recentNotifications$, {
        initialValue: []
    });

    validation: any;
    focus$ = new Subject<string>();
    click$ = new Subject<string>();
    @ViewChild('medicineTypeaheadRef') medicineTypeaheadRef: NgbTypeahead;

    @HostListener('window:keydown.enter', ['$event'])
    handleKeyDown(event: KeyboardEvent) {
        this.search();
    }

    medicijnOnderwerpOptions: RadioOption[] = [
        {
            title: 'De werking',
            value: 'werking' as Medicijnonderwerp
        },
        {
            title: 'Bijwerkingen',
            value: 'bijwerkingen' as Medicijnonderwerp
        },
        {
            title: 'Praktische zaken (bv. verpakking, bijsluiter,...)',
            value: 'praktisch' as Medicijnonderwerp
        }
    ];

    async ngOnInit() {
        const result = await lastValueFrom(
            this.defaultService
                .medicineGetMedicineGroups()
                .pipe(map((res) => res.map((x) => ({ value: `${x.id}`, title: x.naam }))))
        );
        this.medicijnGroupOptions.set(result);
    }

    loadmore() {
        this.RPP$.next(this.RPP$.value + this.RPP);
    }

    scrollToTop(identifier?: string) {
        (identifier ? document.querySelector(identifier) : document.body)?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest'
        });
    }

    search = (reset?: boolean, SEARCH?: SearchMedicineForm) => {
        // this.loadingChecklist = true;
        if (reset) {
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: undefined,
                queryParamsHandling: 'merge'
            });
        } else {
            if (!this.expandForm) {
                delete this.FORM.medicijn;
                delete this.FORM.onderwerp;
                delete this.FORM.medicijnGroep;
            }

            const search = SEARCH ? structuredClone(SEARCH) : structuredClone(this.FORM);
            delete search.rpp;

            const searchObject: SearchMedicineForm = Object.keys(search)?.reduce((acc, key) => {
                const value = search[key as keyof typeof search];

                if (value) {
                    if (typeof value === 'string' && value.length) {
                        acc[key] = value;
                    } else if (Array.isArray(value) && value.length) {
                        acc[key] = value;
                    }
                }

                return acc;
            }, {} as SearchMedicineForm);

            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: {
                    search: searchObject && Object?.keys(searchObject)?.length ? JSON.stringify(searchObject) : null
                },
                queryParamsHandling: 'merge'
            });
            this.scrollToTop('#medicine-results');
        }
    };

    searchMedicines: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.medicineTypeaheadRef.isPopupOpen()));
        const inputFocus$ = this.focus$;

        return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
            distinctUntilChanged(),
            switchMap((term) => {
                return term?.length && term?.length >= 2
                    ? this.defaultService
                          .medicineGetMedicines({ orderby: ['HPK desc'], rPP: 20, term: term })
                          .pipe(map((result) => result.data))
                    : of([]);
            }),
            map((experiences: Medicine[]) => experiences?.map((exp: Medicine) => exp.HPK)) // Map the result to titles
        );
    };

    prepareRoute(outlet: RouterOutlet) {
        const test = outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
        return test;
    }
}

<div class="page page--public background-color--lightgrey">
    <header>
        <!-- <div class="banner"></div> -->
        <app-image-banner
            [backgroundImage]=""
            [height]="bannerHeight + 'px'"
        >
            <div class="content content--center position-relative pt-48">
                <div class="medicine-form">
                    <app-field-text
                        [label]="'Zoek een ervaring'"
                        [placeholder]="'Zoek een ervaring (bv. hoofdpijn)'"
                        [model]="FORM.ervaring"
                        [instant]="true"
                        (modelChange)="FORM.ervaring = $any($event)"
                    ></app-field-text>

                    @if(expandForm){
                        <div class="position-relative responsive-typeahead field">
                            <span class="field-label">Zoek op medicijn</span>
                            
                            <input
                                class="responsive-typeahead form-control"
                                [class.error-field]="validation?.medicijn"
                                [placeholder]="'Zoek hier je medicijn (bv. Ibuprofen)'"
                                id="typeahead-focus"
                                type="text"
                                [(ngModel)]="FORM.medicijn"
                                [ngbTypeahead]="searchMedicines"
                                [selectOnExact]="true"
                                #medicineTypeaheadRef="ngbTypeahead"
                                (focus)="focus$.next($any($event).target.value)"
                                (click)="click$.next($any($event).target.value)"
                            />
                            @if (validation?.medicijn) {
                                <span class="error-field-display">Dit veld is verplicht</span>
                            }
                            
                        </div>
                       
    
                        <app-field-checkboxes
                            [label]="'Toon ervaring over'"
                            [options]="medicijnOnderwerpOptions"
                            [model]="FORM.onderwerp ?? []"
                            [stacked]="true"
                            [classes]="'transparent large-checkbox checkbox-white'"
                            [error]="validation?.overWerking || validation?.overBijwerkingen || validation?.overPraktisch"
                            (modelChange)="FORM.onderwerp = $event"
                        ></app-field-checkboxes>
                    
                        <app-field-select
                            [label]="'Zoek op medicijngroep'"
                            [(model)]="FORM.medicijnGroep"
                            [options]="medicijnGroupOptions()"
                            [placeholder]="'Selecteer'"
                        ></app-field-select>
                    }
                

                    <div class="form-buttons">
                        <app-button [text]="'Zoeken'" (emitClick)="search()"></app-button>
                        <button class="link" (click)="expandForm = !expandForm">{{expandForm ? 'Eenvoudig zoeken' : 'Uitgebreid zoeken'}}</button>
                    </div>
                </div>
            </div>
        </app-image-banner>
    </header>

    <section class="search-results py-dynamic-large" id="medicine-results">
        <div class="content content--center">
            <h1 class="mb-24">{{totalRows}} Resultaten</h1>
            
            <div class="cluster cluster--col-3 gap-24">
                
                @if (!ready) {
                    @for (notification of skeletonCount; track $index) {

                        <div class="cluster-item box-shadow radius-8 gap-16 recent-medicine p-24 aspect-ratio--1">
                            <div class="notification-header w-100">
                                <div class="info w-100 skeleton skeleton--paragraph" style="height: 50px;"></div>
                            </div>
                            <div class="medicine-descr truncate truncate--7">
                                <div class="skeleton skeleton--paragraph"></div>
                                <div class="skeleton skeleton--paragraph"></div>
                                <div class="skeleton skeleton--paragraph"></div>
                            </div>
                        </div>
                    }
                } @else {
                    @for (item of recentNotifications(); track item.slug) {
                        <article class="cluster-item box-shadow radius-8 gap-16 recent-medicine p-24 cursor-pointer saving--pre"  [ngClass]="{'saving' : loading, 'skeleton' : loading, 'skeleton--insverse' : loading}" [routerLink]="['medicijn',item.slug]" queryParamsHandling="preserve">
                            <div class="notification-header">
                                <i class="im-icon experience-icon" [style.background-image]="'url(/assets/img/icons/experience_score-' + item.ervaringIndicator + '.svg)'"></i>
    
                                <div class="info">
                                    <span class="medicine-title">{{item.medicijn}}</span>
                                    <span class="date">{{item.createTS | formatTs : 'D MMMM YYYY'}}</span>
                                </div>
                            </div>
                            <div class="medicine-descr truncate truncate--7">
                                {{item.ervaringDescr}}
                            </div>
                            <a class="link mt-auto" [routerLink]="['medicijn',item.slug]" queryParamsHandling="preserve">Lees verder</a>
                        </article>
                    } @empty {
                        <div>Geen resultaten gevonden</div>
                    }
                 
                }
            </div>

            @if(totalRows > recentNotifications()?.length){
                <!-- <button (click)="search({rpp : (searchParams$.value.rpp + 30)})" class="link link-center mt-24 loadmore" [class.saving]="loading" [attr.inert]="loading ? '' : null"> -->
                <button (click)="loadmore()" class="link link-center mt-24 loadmore" [class.saving]="loading" [attr.inert]="loading ? '' : null">
                    @if (loading) {
                        <app-inline-spinner></app-inline-spinner>
                    }
                    toon meer ervaringen
                </button>
            }
        </div>
    </section>
</div>


<div [@slideUp]="outlet.activatedRouteData?.animation">
    <router-outlet #outlet="outlet"></router-outlet>
</div>

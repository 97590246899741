<div class="popup">
    <button (click)="activeModal.dismiss()" class="close-icon">
        <i class="icon icon-close-circle"></i>
    </button>
    <div>
        <h1>{{popup.titel}}</h1>
        <p class="color-primary">{{popup.inhoud}}</p>
    </div>
    
    @if (popup.afbeeldingURL) {
        <img [src]="popup.afbeeldingURL" [alt]="popup.titel">
    }

    @if (popup.knop) {
        <a [href]="popup.knop.link" [target]="popup.knop.nieuwTabblad ? '_blank' : '_self'" class="app-button primary-button text-decoration-none">{{popup.knop.titel}}</a>
    }
</div>
